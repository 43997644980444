<template>
  <div class="d-flex flex-row flex-row-fluid justify-content-center">
    <Loader v-bind:logo="loaderLogo"></Loader>
    <div v-if="accepted" class="row d-flex w-75">
      <div class="col-12 d-lg-none">
        <img class="w-100 h-auto" src="/media/svg/humans/hangout.svg" alt="" />
      </div>
      <div class="col-12 col-lg-6 d-flex flex-column justify-content-center">
        <h1 class="page-title my-10">
          Trusteeship Accepted
        </h1>
        <h4 class="page-subtitle mb-10">
          <p>
            You are now a trustee for
            <span class="highlight"
              >{{ userDisplayName }}'s <strong>{{ appName }}</strong></span
            >
            account. Please check your emails for the secret code. If you have not received it, please check your junk
            mail.
          </p>
          <p>
            When {{ userDisplayName }} passes on, please click the link in the email to begin the process of unlocking
            their account and dispatching their final messages to friends, family and loved ones.
          </p>
          <p class="pb-5">
            Please bear in mind that you are one of three trustees. Only when all three trustees enter their secret key
            will the messages get dispatched.
          </p>
          <p class="pb-5">
            <strong>Would you also like to setup your {{ appName }} account? It will only take a few minutes</strong>
          </p>
          <button @click="goHome()" class="btn btn-primary btn-lg w-100">
            Setup My Account
            <i class="fas fa-arrow-right"></i>
          </button>
        </h4>
      </div>
      <div class="col-lg-6 d-none d-lg-block">
        <img class="w-100 h-auto" src="/media/svg/humans/hangout.svg" alt="" />
      </div>
    </div>
    <div v-else-if="declined" class="row d-flex w-75">
      <div class="col-12 d-lg-none">
        <img class="w-100 h-auto" src="/media/svg/humans/hangout.svg" alt="" />
      </div>
      <div class="col-12 col-lg-6 d-flex flex-column justify-content-center">
        <h1 class="page-title my-10">
          Trusteeship Declined
        </h1>
        <h4 class="page-subtitle mb-10">
          Thank you for the feedback. Your trusteeship is declined and
          <span class="highlight">{{ userDisplayName }}'s</span> was informed.
        </h4>
      </div>
      <div class="col-lg-6 d-none d-lg-block">
        <img class="w-100 h-auto" src="/media/svg/humans/hangout.svg" alt="" />
      </div>
    </div>
    <div v-else-if="verified" class="row d-flex w-75">
      <div class="col-12 d-lg-none">
        <img class="w-100 h-auto" src="/media/svg/humans/hangout.svg" alt="" />
      </div>
      <div class="col-12 col-lg-6 d-flex flex-column justify-content-center">
        <h1 class="page-title my-10">Hello {{ trustee.name }}</h1>
        <h4 class="page-subtitle mb-10">
          <p class="pb-5">
            <span class="highlight capitalize">{{ userDisplayName }}</span>
            invited you to be a trustee for their
            <span class="highlight capitalize text text-bold">{{ appName }}</span>
            account. They use this platform to send final wishes to friends and loved ones after passing on.
          </p>
          <p>
            If you accept the trusteeship, you will receive a link. Then, when
            <span class="highlight capitalize">{{ userDisplayName }}</span>
            passes on, you will be required to use that link to confirm their passing and begin the process of
            dispatching their messages.
          </p>
          <p>
            After confirming their passing, the system will dispatch all the messages they have prepared for friends and
            loved ones.
          </p>
        </h4>
        <div class="action-button d-flex flex-row ">
          <button @click="respond('accept')" class="btn btn-lg btn-success mr-5 px-10">
            Accept
          </button>
          <button @click="respond('decline')" class="btn btn-lg btn-danger px-10">
            Decline
          </button>
        </div>
      </div>
      <div class="col-lg-6 d-none d-lg-block">
        <img class="w-100 h-auto" src="/media/svg/humans/hangout.svg" alt="" />
      </div>
    </div>
    <div v-else class="row d-flex w-75">
      <div class="col-12 d-lg-none">
        <img class="w-100 h-auto" src="/media/svg/humans/hangout.svg" alt="" />
      </div>
      <div class="col-12 col-lg-6 d-flex flex-column justify-content-center">
        <h1 class="page-title my-10">
          Invalid Trustee
        </h1>
        <h4 class="page-subtitle mb-10">
          The link you used to visit this page is invalid or has expired. If you received an invitation from a
          <span class="highlight">{{ appName }}</span> user, please ask them to resend the invitation email.
        </h4>
      </div>
      <div class="col-lg-6 d-none d-lg-block">
        <img class="w-100 h-auto" src="/media/svg/humans/hangout.svg" alt="" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.page-subtitle {
  font-weight: 400;
  line-height: 1.5em;
}
h4::first-letter {
  text-transform: uppercase;
}
.highlight {
  border-bottom: 2px dotted rgba($color: #000000, $alpha: 0.1);
}
</style>
<script>
import { mapGetters } from "vuex";
import { GET_TRUSTEE, ACCEPT_TRUSTEE, DECLINE_TRUSTEE } from "@/core/services/store/trustees.module";
import { ADD_BODY_CLASSNAME, REMOVE_BODY_CLASSNAME } from "@/core/services/store/htmlclass.module.js";

import Loader from "@/view/content/Loader.vue";
import Swal from "sweetalert2";

export default {
  name: "activateTrustee",
  components: {
    Loader
  },
  data: function() {
    return {
      trustee: {},
      verified: false,
      accepted: false,
      declined: false
    };
  },

  computed: {
    ...mapGetters(["appName", "layoutConfig", "homeUrl", "buttonSpinner"]),

    userDisplayName: function() {
      return this.trustee.user ? this.trustee.user.display_name : "";
    },

    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig("loader.logo");
    },

    unlockUrl() {
      return this.homeUrl + "unlock";
    }
  },

  beforeMount: function() {
    this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
  },

  mounted: function() {
    // get the user
    const key = this.$route.params.key;
    this.$store
      .dispatch(GET_TRUSTEE, key)

      .then(response => {
        this.trustee = response.data;
        this.verified = true;
      })

      .catch(() => {
        this.verified = false;
      })

      .finally(() => {
        this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
      });
  },

  methods: {
    respond: function(action) {
      Swal.fire({
        text: `Are you sure you want to ${action} the trusteeship?`,
        icon: "warning",
        buttonsStyling: false,
        heightAuto: false,
        showCancelButton: true,
        confirmButtonText: "Yes I'm sure!",
        cancelButtonText: "No wait...",
        customClass: {
          confirmButton: "btn font-weight-bold btn-light-primary",
          cancelButton: "btn font-weight-bold btn-default"
        }
      }).then(status => {
        if (status.isConfirmed) {
          // set the page to loading
          this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

          // update the server
          const DISPATCH_METHOD = action == "accept" ? ACCEPT_TRUSTEE : DECLINE_TRUSTEE;
          this.$store
            .dispatch(DISPATCH_METHOD, this.trustee.trustee_key)

            .then(() => {
              if (action == "accept") {
                this.accepted = true;
              } else {
                this.declined = true;
              }
            })

            .catch(error => {
              Swal.fire({
                text: error.message,
                icon: "error",
                buttonsStyling: false,
                heightAuto: false,
                showCancelButton: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn font-weight-bold btn-light-primary",
                  cancelButton: "btn font-weight-bold btn-default"
                }
              });
            })

            .finally(() => {
              this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
            });
        }
      });
    },
    goHome: function() {
      this.$router.push({ name: "dashboard" });
    }
  }
};
</script>
